<template>
	<div ref="container"></div>
</template>

<script>
import Player from "xgplayer";
import "xgplayer/dist/index.min.css";

export default {
	name: "XgPlayer",
	props: {
		url: {
			type: String,
			defaultValue: "",
		},
	},
	data() {
		return {
			player: null,
		};
	},
	mounted() {
		this.player = new Player({
			el: this.$refs.container,
			url: this.url,
		});
	},
	destroyed() {
		this.player.destroy();
	},
	watch: {
		url(newValue) {
			if (!this.player) {
				return;
			}

            console.log('changeurl')

			this.player.destroy();

			this.player = new Player({
				el: this.$refs.container,
				url: newValue,
			});
		},
	},
};
</script>

<style></style>
